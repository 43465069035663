export const Select = ({ ...props }) => {
  return (
    <select
      {...props}
      className="bg-bgAlt px-3 py-5 type-label-l rounded-sm text-text placeholder:text-accent border-r-[16px] border-r-solid border-bgAlt"
    >
      <option value={""}>{props.placeholder ?? "Please select"}</option>
      {props.choices.map(
        (
          choice: {
            value: string
            text: string
          },
          index: number,
        ) => (
          <option key={index} value={choice.value}>
            {choice.text}
          </option>
        ),
      )}
    </select>
  )
}
