import { GFFieldComponentProps } from "eddev/integrations/gravityforms"
import { useId } from "react"

export function RadioGroup(props: GFFieldComponentProps<"radio">) {
  const group = useId()

  return (
    <div className="checkbox-group">
      {props.choices.map((choice, index) => (
        <Radio
          key={index}
          label={choice.text}
          checked={props.value === choice.value}
          onCheckedChange={() => props.set(choice.value)}
          name={group}
        />
      ))}
    </div>
  )
}

type Props = {
  onCheckedChange: () => void
  checked: boolean
  label: string
  name: string
}

export function Radio(props: Props) {
  const id = useId()

  return (
    <div className="flex items-center gap-4 py-2">
      <input
        type="radio"
        name={props.name}
        aria-label={props.label}
        checked={props.checked}
        id={id}
        onChange={(e) => {
          if (e.target.checked) props.onCheckedChange()
        }}
        className="hidden [&:checked+label>div>div]:flex"
      />

      <label htmlFor={id} className="type-label-m text-text cursor-pointer flex items-center gap-4 py-2">
        <div className="w-5 h-5 flex items-center justify-center border rounded-full">
          <div className="w-3 h-3 hidden bg-text rounded-full" />
        </div>
        <span dangerouslySetInnerHTML={{ __html: props.label }} />
      </label>
    </div>
  )
}
