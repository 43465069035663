export const TextArea = ({ ...props }) => {
  return (
    <textarea
      className="bg-bgAlt px-3 py-5 type-label-l rounded-sm text-text placeholder:text-accent"
      {...props}
      rows={12}
      inputMode={props.inputMode}
      aria-label={props.label || props.placeholder}
    />
  )
}
