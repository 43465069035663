import { CheckboxGroup } from "@components/ui/CheckboxGroup"
import { Input } from "@components/ui/Input"
import { RadioGroup } from "@components/ui/RadioGroup"
import { Select } from "@components/ui/Select"
import { TextArea } from "@components/ui/TextArea"
import {
  GFAddressData,
  GFAddressOptions,
  GFConsentOptions,
  GFFieldComponentSet,
  GFFileUploadOptions,
  GFGenericTextOptions,
  GFNameData,
  GFNameOptions,
  GFNumberOptions,
  GFSelectOptions,
  GFTextOptions,
  GravityFormsFieldState,
} from "eddev/integrations/gravityforms"
import { PropsWithChildren, useId } from "react"

export const FIELD_TYPES: GFFieldComponentSet = {}

function FieldWrapper(
  props: PropsWithChildren<{ isRequired?: boolean; showLabel?: boolean; label?: string; labelId?: string }>,
) {
  return (
    <div className="flex flex-col gap-3">
      {props.showLabel && (
        <label htmlFor={props.labelId} className="type-label-m text-text">
          {props.label} {props.isRequired ? "*" : null}
        </label>
      )}
      {props.children}
    </div>
  )
}

FIELD_TYPES["text"] = (props: GravityFormsFieldState<string, GFTextOptions>) => {
  const id = useId()

  return (
    <FieldWrapper labelId={id} {...props}>
      <Input
        defaultValue={props.value}
        onChange={(e: any) => props.set(e.currentTarget.value)}
        id={id}
        type={props.type}
        required={props.isRequired}
        disabled={props.disabled}
        placeholder={`${props?.placeholder}`}
      />
    </FieldWrapper>
  )
}

FIELD_TYPES["textarea"] = (props: GravityFormsFieldState<string, GFTextOptions> & { dark?: boolean }) => {
  const id = useId()

  return (
    <FieldWrapper {...props}>
      <TextArea
        defaultValue={props.value}
        onChange={(e: any) => props.set(e.currentTarget.value)}
        id={id}
        label={props.label}
        required={props.isRequired}
        disabled={props.disabled}
        placeholder={`${props?.placeholder}`}
        dark={props.dark}
      />
      {props.description && (
        <span className="type-label-m text-accent" dangerouslySetInnerHTML={{ __html: props.description }} />
      )}
    </FieldWrapper>
  )
}

FIELD_TYPES["select"] = (props: GravityFormsFieldState<string, GFSelectOptions>) => {
  const id = useId()

  return (
    <FieldWrapper {...props} labelId={id}>
      <Select
        value={props.value ?? ""}
        id={id}
        required={props.isRequired}
        disabled={props.disabled}
        choices={props.choices}
        onChange={(e: any) => props.set(e.currentTarget.value)}
      />
    </FieldWrapper>
  )
}

FIELD_TYPES["checkbox"] = (props: GravityFormsFieldState<string[], GFSelectOptions>) => {
  const id = useId()

  return (
    <FieldWrapper {...props}>
      <CheckboxGroup {...props} />
    </FieldWrapper>
  )
}

FIELD_TYPES["radio"] = (props: GravityFormsFieldState<string, GFSelectOptions>) => {
  return (
    <FieldWrapper {...props}>
      <RadioGroup {...props} />
    </FieldWrapper>
  )
}

FIELD_TYPES["email"] = (props: GravityFormsFieldState<string, GFGenericTextOptions>) => {
  const id = useId()

  return (
    <FieldWrapper {...props} labelId={id}>
      <Input
        type="email"
        defaultValue={props.value}
        onChange={(e: any) => props.set(e.currentTarget.value)}
        id={id}
        label={props.label}
        required={props.isRequired}
        disabled={props.disabled}
        placeholder={`${props?.placeholder}`}
        dark={props.dark}
      />
    </FieldWrapper>
  )
}

FIELD_TYPES["fileupload"] = (
  props: GravityFormsFieldState<File | FileList | File[], GFFileUploadOptions> & { dark?: boolean },
) => {
  const id = useId()

  return (
    <FieldWrapper {...props} labelId={id}>
      <input
        type="file"
        onChange={(e) => props.set(e.currentTarget.files!)}
        disabled={props.disabled!}
        required={props.isRequired}
        multiple={props.multipleFiles}
      />
    </FieldWrapper>
  )
}

// FIELD_TYPES["number"] = (props: GravityFormsFieldState<number, GFNumberOptions> & { dark?: boolean }) => {
//   const id = useId()

//   return (
//     <FieldWrapper {...props} labelId={id}>
//       <InputNumber
//         type="number"
//         value={props.value ?? ""}
//         onChange={(e) => props.set(e.currentTarget.valueAsNumber)}
//         id={id}
//         label={props.label}
//         required={props.isRequired}
//         disabled={props.disabled}
//         placeholder={props.placeholder}
//         size={props.size}
//       />
//     </FieldWrapper>
//   )
// }

// FIELD_TYPES["html"] = (props: GravityFormsFieldState<void, {}> & { dark?: boolean }) => {
//   return (
//     <HTMLContainer>
//       <Html content={props.content} />
//     </HTMLContainer>
//   )
// }

// FIELD_TYPES["section"] = (props: GravityFormsFieldState<void, {}> & { dark?: boolean }) => {
//   return (
//     <Section>
//       <Heading>
//         {props.label} <i>{props.isRequired ? "*" : ""}</i>
//       </Heading>
//       <hr />
//     </Section>
//   )
// }

// FIELD_TYPES["name"] = (props: GravityFormsFieldState<GFNameData, GFNameOptions> & { dark?: boolean }) => {
//   return (
//     <FieldWrapper {...props}>
//       <NameGroup {...props} dark={props.dark} />
//     </FieldWrapper>
//   )
// }

// FIELD_TYPES["date"] = (props: GravityFormsFieldState<string, {}> & { dark?: boolean }) => {
//   const id = useId()

//   return (
//     <FieldWrapper {...props} labelId={id}>
//       <DateField value={props.value} onValueChange={(date) => props.set(date!)} id={id} />
//     </FieldWrapper>
//   )
// }

// FIELD_TYPES["time"] = (props: GravityFormsFieldState<string, {}> & { dark?: boolean }) => {
//   const id = useId()

//   return (
//     <FieldWrapper {...props} labelId={id}>
//       <TimeField value={props.value} onValueChange={(value) => props.set(value!)} id={id} />
//     </FieldWrapper>
//   )
// }

// FIELD_TYPES["phone"] = (props: GravityFormsFieldState<string, GFGenericTextOptions> & { dark?: boolean }) => {
//   const id = useId()

//   return (
//     <FieldWrapper {...props} labelId={id}>
//       <InputText
//         defaultValue={props.value}
//         onChange={(e) => props.set(e.currentTarget.value)}
//         id={id}
//         type="tel"
//         label={props.label}
//         required={props.isRequired}
//         disabled={props.disabled}
//         placeholder={`${props?.placeholder}`}
//         autoComplete="tel"
//         dark={props.dark}
//       />
//     </FieldWrapper>
//   )
// }

// FIELD_TYPES["address"] = (props: GravityFormsFieldState<GFAddressData, GFAddressOptions> & { dark?: boolean }) => {
//   return (
//     <FieldWrapper {...props}>
//       <AddressGroup {...props} />
//     </FieldWrapper>
//   )
// }

// FIELD_TYPES["website"] = (props: GravityFormsFieldState<string, GFGenericTextOptions> & { dark?: boolean }) => {
//   const id = useId()

//   return (
//     <FieldWrapper {...props} labelId={id}>
//       <InputText
//         type="url"
//         defaultValue={props.value}
//         onChange={(e) => props.set(e.currentTarget.value)}
//         id={id}
//         label={props.label}
//         required={props.isRequired}
//         disabled={props.disabled}
//         placeholder={props?.placeholder}
//       />
//     </FieldWrapper>
//   )
// }

// FIELD_TYPES["captcha"] = (props: GravityFormsFieldState<any, {}> & { dark?: boolean }) => {
//   return (
//     <FieldWrapper {...props}>
//       <Captcha {...props} />
//     </FieldWrapper>
//   )
// }

// FIELD_TYPES["multiselect"] = (props: GravityFormsFieldState<string[], GFSelectOptions> & { dark?: boolean }) => {
//   const id = useId()

//   return (
//     <FieldWrapper {...props} labelId={id}>
//       <Multiselect
//         value={props.value ?? []}
//         id={id}
//         required={props.isRequired}
//         disabled={props.disabled}
//         choices={props.choices}
//         onChange={props.set}
//       />
//     </FieldWrapper>
//   )
// }

// FIELD_TYPES["consent"] = (props: GravityFormsFieldState<boolean, GFConsentOptions> & { dark?: boolean }) => {
//   const id = useId()

//   return (
//     <FieldWrapper {...props} hideText>
//       <Checkbox
//         label={props.checkboxLabel}
//         required={props.isRequired}
//         disabled={props.disabled}
//         checked={props.value ?? false}
//         onCheckedChange={(checked) => props.set(checked)}
//       />

//       {props?.description && (
//         <>
//           <VSpace initial={2} />
//           <Description dangerouslySetInnerHTML={{ __html: props.description }} />
//         </>
//       )}
//     </FieldWrapper>
//   )
// }

// const Section = styled("div", {
//   my: "$7",

//   hr: {
//     mt: "$3",
//     border: "none",
//     borderTop: "1px solid $fg",
//   },
// })

// const HTMLContainer = styled("div", {
//   my: "$7",
// })

// const Description = styled(Typography, {
//   fontSize: "14px",
//   fontStyle: "italic",

//   "> a": { textDecoration: "underline" },
// })

// const ErrorMessage = styled(Typography, {
//   typography: "label-s",
//   color: "$error",

//   variants: {
//     dark: {
//       true: {
//         color: "$errorLight",
//       },
//     },
//   },
// })

// export function FieldWrapper(
//   props: PropsWithChildren<GravityFormsFieldState & { labelId?: string; hideText?: boolean; dark?: boolean }>,
// ) {
//   return (
//     <FieldWrapperStyle className={!!props.error ? `error` : ``} dark={props.dark}>
//       {!props.hideText && props.labelPlacement !== "hidden_label" && !props.hideText && (
//         <Label htmlFor={props.labelId} required={props.isRequired}>
//           {props.label}
//         </Label>
//       )}
//       {props.children}
//       {!!props.error && <ErrorMessage dangerouslySetInnerHTML={{ __html: props.error }} dark={props.dark} />}
//       {!!props.description && !props.hideText && (
//         <Description dangerouslySetInnerHTML={{ __html: props.description }} />
//       )}
//     </FieldWrapperStyle>
//   )
// }

// const FieldWrapperStyle = styled("div", {
//   width: "100%",
//   display: "flex",
//   flexDirection: "column",
//   gap: "$1",

//   "&.error": {
//     label: {
//       color: "$error",
//     },

//     input: {
//       borderColor: "$error",
//       color: "$error",
//     },
//   },

//   variants: {
//     dark: {
//       true: {
//         "&.error": {
//           label: {
//             color: "$errorLight",
//           },

//           input: {
//             borderColor: "$errorLight",
//             color: "$errorLight",
//           },
//         },
//       },
//     },
//   },
// })
