import { GFFieldComponentProps } from "eddev/integrations/gravityforms"
import { useId } from "react"

export function CheckboxGroup(props: GFFieldComponentProps<"checkbox">) {
  const value = props.value ?? []

  return (
    <div className="checkbox-group">
      {props.choices.map((choice, index) => (
        <Checkbox
          key={index}
          label={choice.text}
          name={props.name}
          checked={value.includes(choice.value)}
          onCheckedChange={(checked) => {
            props.set(
              checked
                ? [...value.filter((v) => v !== choice.value), choice.value]
                : value.filter((v) => v !== choice.value),
            )
          }}
        />
      ))}
    </div>
  )
}

type Props = {
  onCheckedChange: (checked: boolean) => void
  checked: boolean
  label: string
  name?: string
  required?: boolean
  disabled?: boolean
}

export function Checkbox(props: Props) {
  const id = useId()

  return (
    <div className="flex items-center gap-4 py-2">
      <input
        type="checkbox"
        aria-label={props.label}
        id={id}
        name={props.name}
        required={props.required}
        disabled={props.disabled}
        checked={props.checked}
        onChange={(e) => {
          props.onCheckedChange(e.currentTarget.checked)
        }}
        className="hidden [&:checked+label>div>div]:flex"
      />

      <label htmlFor={id} className="type-label-m text-text cursor-pointer flex items-center gap-4 py-2">
        <div className="w-5 h-5 flex items-center justify-center border">
          <div className="w-3 h-3 hidden bg-text" />
        </div>
        <span dangerouslySetInnerHTML={{ __html: props.label }} />
      </label>
    </div>
  )
}
