import {
  GravityFormData,
  GravityFormState,
  GravityFormsFieldState,
  useGravityForm,
} from "eddev/integrations/gravityforms"
import { useMemo } from "react"
import { FIELD_TYPES } from "./gf-field-types"
import { Button } from "@components/atoms/Button"

type Props = {
  form: GravityFormData
  onSubmitted?: (data: GravityFormData) => void
  onChange?: (data: GravityFormState) => void
  dark?: boolean
  paginated?: boolean
}

type FieldGroupItem = {
  field: GravityFormsFieldState
  columns: number
}

export function GravityForm(props: Props) {
  const state = useGravityForm({
    form: props.form,
  })

  const fieldRows = useMemo(() => {
    const rows: FieldGroupItem[][] = []

    let lastGroupID = ""
    for (const field of state.fields) {
      if (field.type === "hidden" || field.visibility === "hidden") continue
      if (field.layoutGroupId !== lastGroupID) {
        rows.push([])
        lastGroupID = field.layoutGroupId
      }
      rows[rows.length - 1].push({
        field,
        columns: field.layoutGridColumnSpan ?? 1,
      })
    }

    return rows
  }, [state])

  return (
    <>
      {state.complete ? (
        <div
          className="type-title-s text-text text-center"
          dangerouslySetInnerHTML={{ __html: state.successMessage ?? "Thank you for submitting the form." }}
        />
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            if (state.submitting) return
            console.log(state)
            state.submit()
          }}
          autoComplete="false"
          className="w-full flex flex-col gap-6"
        >
          <main className="flex flex-col gap-6 w-full">
            {fieldRows.map((row, i) => {
              const totalColumns = row.reduce((sum, { columns }) => sum + columns, 0)
              return (
                <div key={i} className="form-row">
                  {row.map(({ field, columns }, i) => {
                    const Component = (FIELD_TYPES as any)[field.type]
                    if (!Component) {
                      console.info("Unknown field type", field)
                    }
                    if (Component) {
                      return (
                        <div
                          key={i}
                          className="form-row-item"
                          style={{ "--field-columns": columns / totalColumns } as any}
                        >
                          <Component {...field} disabled={field.disabled || state.submitting} />
                        </div>
                      )
                    } else {
                      return null
                    }
                  })}
                </div>
              )
            })}
          </main>

          <footer className="flex gap-5 items-center justify-end">
            {/* <Button size="m" style="secondary" type="reset">
              Clear
            </Button> */}

            <Button
              size="m"
              style="primary"
              iconRight="ARROW_RIGHT"
              type="submit"
              disabled={state.submitting ? true : false}
            >
              {props.form.button?.text === ""
                ? "Submit"
                : props.form.button.text === "&nbsp;"
                  ? ""
                  : props.form.button.text}
            </Button>
          </footer>
        </form>
      )}
    </>
  )
}
